import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Link, FormattedMessage } from "gatsby-plugin-intl"

class LinkExpired extends React.Component {
  render () {
    return (
    <Layout>

      <SEO title="Link Expired" />

      <div className="white-container mb-5">


        <div className="text-center mb-4">

          <span className="icon-error fs-40"></span>

        </div>

        <h1 className="text-center"><FormattedMessage id="1_1_2_login_activate_link_expired.title" /></h1>

        <p className="text-center mb-4"><FormattedMessage id="1_1_2_login_activate_link_expired.description" /></p>


        <div className="">
          <Link to="/sessions/login" className="button red mx-auto"><FormattedMessage id="generic.forms.form_buttons.send_new_link" /></Link>
        </div>

      </div>

    </Layout>
    )
  }
}


export default LinkExpired
